.button {
  height: 36px;
  gap: 8px;
  border: none;
  box-sizing: border-box;
  padding: 4px 20px;
  border-radius: 4px;
  font-weight: 600;
  font-family: "Inter";
  letter-spacing: 0.25px;
  cursor: pointer;
  position: relative;
  font-size: 14px;
  text-align: start;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.content {
  display: flex;
  gap: 8px;
  align-items: center;
}

.primary {
  color: white;
  background-color: var(--color-primary);
}

.primary:hover:not(.loading):not(:disabled) {
  background-color: #00a7d2;
}

.secondary {
  background-color: white;
  color: var(--color-primary);
  border: 1px solid var(--color-primary);
}

.secondary:hover {
  background-color: var(--color-background);
}

.loading *:not(.loader) {
  opacity: 0;
}

.loader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  opacity: 0;
}

.loading .loader {
  opacity: 1;
}

.primary:disabled {
  background-color: #a9a9a9;
  cursor: unset;
}
