.form {
  max-width: 40rem;
}
.pageBody {
  background-color: white;
  border: 1px solid var(--accent-faint);
  border-radius: 15px;
  box-shadow: var(--default-box-shadow);
  overflow: hidden;
  margin-bottom: 32px;
}

.pageBodyHeading {
  padding: 16px 24px;
  width: 100%;
  border-bottom: 1px solid var(--color-background);
  background-color: #fafafa;
  font-size: 12px;
  color: #757575;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 600;
}

.inputContainer label {
  font-size: 14px;
  margin-bottom: 4px;
}

.label,
.input,
.select {
  display: block;
}

.input,
.select {
  width: 100%;
  box-sizing: border-box;
  padding: 8px;
  border: 1px solid #d6d6d6;
  border-radius: 4px;
  font-size: 15px;
}
.select {
  max-width: 400px;
}

.input {
  width: 100%;
}

.inputs {
  padding: 16px 24px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding-top: 16px;
  gap: 16px 24px;
  padding-bottom: 40px;
}

.submitWrap {
  display: flex;
  justify-content: flex-end;
  gap: 24px;
  align-items: center;
}

.cancelButton {
  border: 0;
  padding: 0;
  background: 0;
  display: block;
  cursor: pointer;
  font-size: 15px;
  font-weight: 600;
  color: #616161;
  text-decoration: none;
}

.checkBoxLabel {
  display: inline;
  margin-left: 4px;
}

.error {
  color: var(--color-destructive);
  font-size: 13px;
  margin-top: 4px;
}

.inputLong {
  grid-column: 1/3;
}

.radioContainer {
  display: flex;
  gap: 16px;
}

.radioContainerInputs {
  display: flex;
  align-items: center;
  gap: 16px;
}

.radioContainerInput {
  display: flex;
  align-items: center;
  gap: 4px;
}

.radioContainerInput label {
  margin-top: 4px;
}

.communicationLabel {
  width: 200px;
  margin-top: 6px;
}

.error {
  font-size: 14px;
  color: var(--color-destructive);
  margin-top: 8px;
}
