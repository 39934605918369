.detail {
  position: fixed;
  inset: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.card {
  background: white;
  border-radius: 10px;
  padding: 32px;
  display: flex;
  margin: 0 auto;
  gap: 8px;
  box-sizing: border-box;
  width: 500px;
  position: relative;
  flex-direction: column;
  max-height: 800px;
}

.content {
  display: flex;
  line-height: 1.5;
}

.name {
  font-size: 24px;
  font-weight: bold;
  line-height: 1.3;
}

.strength,
.stock,
.treatmentLength {
  color: #757575;
}

.price {
  font-size: 24px;
  font-weight: 600;
  margin-top: 16px;
}

.exit {
  position: absolute;
  right: 8px;
  top: 16px;
  background: 0;
  border: 0;
  cursor: pointer;
}

.exit img {
  width: 12px;
  opacity: 50%;
}

.buttonWrap {
  width: 100%;
}

.treatmentLength {
  padding-bottom: 24px;
  font-weight: 500;
}

.genericName {
  font-weight: 400;
  font-size: 14px;
  color: #757575;
}

.select {
  width: 100%;
  padding: 8px;
  border-radius: 5px;
  background-color: white;
  border: 1px solid #bdbdbd;
  font-size: 14px;
}

.customQuantityInput {
  width: 100%;
  padding: 9px 8px;
  border-radius: 5px;
  background-color: white;
  border: 1px solid #bdbdbd;
  font-size: 14px;
  box-sizing: border-box;
}

.selectError,
.inputError {
  outline: 1px solid var(--color-destructive);
  border-color: transparent;
}

.selectors {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.selectors .label {
  color: #424242;
  line-height: 1.4;
  font-size: 14px;
}

.error {
  color: var(--color-destructive);
  font-size: 14px;
  margin-bottom: 4px;
  margin-top: 4px;
  line-height: 1.3;
}

.priceUnits {
  font-size: 14px;
  font-weight: 400;
  color: #757575;
}

.buttonWrap {
  margin-top: 16px;
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.selectorWrap {
  margin-top: 8px;
}

.medicationFees {
  padding: 12px 16px;
  border: 1px solid #e0e0e0;
  background-color: #fafafa;
  border-radius: 5px;
}

.stock {
  font-size: 14px;
}

.quantitySelectors {
  display: flex;
  gap: 8px;
}

.dueDateInput {
  padding: 8px;
  border-radius: 5px;
  background-color: white;
  border: 1px solid #bdbdbd;
  font-size: 14px;
  width: 100%;
  box-sizing: border-box;
  font-family: "Inter";
}

.dueDateError {
  border: 1px solid var(--color-destructive);
}

.flexControls {
  display: flex;
  gap: 8px;
  width: 100%;
}

.flexControls > div {
  width: 100%;
}

.previouslyFilledContainer {
  margin-top: 4px;
}
.previouslyFilledContainer label {
  font-size: 14px;
  margin-left: 4px;
}

.summary {
  margin-top: 8px;
  font-size: 14px;
  font-weight: 600;
  text-align: center;
  border: 1px solid var(--accent-faint);
  padding: 8px;
  background-color: #fafafa;
  border-radius: 5px;
}
