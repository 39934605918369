.infoDialog {
  text-align: center;
  padding: 40px;
  box-sizing: border-box;
  width: 500px;
}

.textWrap {
  margin-bottom: 24px;
}

.infoDialog h3 {
  line-height: 1.3;
  margin-bottom: 8px;
  font-size: 24px;
}

.infoDialog p {
  line-height: 1.5;
  color: #757575;
}
