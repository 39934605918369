.download {
  width: 24px;
  height: 24px;
  background-color: white;
  box-shadow: var(--default-box-shadow);
  border-radius: 500px;
  padding: 20px;
  border: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border: 1px solid var(--accent-faint);
  position: relative;
}

.download:active {
  opacity: 0.7;
}

.download img {
  width: 24px;
  height: 24px;
}

.base {
  position: relative;
}
