.orderHistoryConsentLink {
  padding: 32px;
  width: 600px;
}

.title {
  margin-bottom: 4px;
}

.subtitle {
  margin-bottom: 12px;
  font-size: 14px;
  line-height: 1.5;
  color: #616161;
}

.warning {
  width: 100%;
  background: #fff8e1;
  padding: 8px;
  border: 2px solid #ffe082;
  box-sizing: border-box;
  border-radius: 7px;
  margin-bottom: 16px;
  display: flex;
  gap: 16px;
  align-items: center;
  font-size: 14px;
  line-height: 1.5;
}

.warningIcon {
  width: 40px;
  height: 40px;
  padding-left: 8px;
}

.warningHeader {
  font-weight: 600;
}

.templates {
  margin-bottom: 24px;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.successText {
  font-size: 14px;
  text-align: right;
}

.templateBox {
  padding: 16px;
  border-radius: 5px;
  color: var(--color-black);
  border: 1px solid #e0e0e0;
  font-size: 14px;
  line-height: 1.2;
  white-space: pre-wrap;
  cursor: text;
  position: relative;
  font-family: sans-serif;
}

.copyBox {
  height: 32px;
  width: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  cursor: pointer;
  transition: opacity 100ms ease-in-out;
  background-color: transparent;
  position: absolute;
  border: 0;
  opacity: 1;
}

.copySubject {
  top: 8px;
  right: 8px;
}

.copyContent {
  bottom: 8px;
  right: 8px;
}

.copyBox:active {
  opacity: 0.8;
}

.subject {
  padding-bottom: 8px;
  margin-bottom: 16px;
  border-bottom: 1px solid #e0e0e0;
}
