.editDispenseDateCard {
  padding: 32px;
  width: 500px;
}

.editDispenseDateDescription {
  margin-bottom: 16px;
  line-height: 1.4;
  font-size: 15px;
  color: #616161;
}

.editDispenseDateCard h3 {
  line-height: 1.3;
  margin-bottom: 12px;
  font-size: 20px;
}

.editDispenseDateCard label {
  font-size: 14px;
  margin-bottom: 4px;
  display: block;
}

.errorMsg {
  margin-top: 8px;
}

.editDispenseDateActions {
  display: flex;
  gap: 10px;
  justify-content: flex-end;
  margin-top: 16px;
}

.dispenseDateInput {
  width: 100%;
  box-sizing: border-box;
  padding: 8px;
  border: 1px solid #d6d6d6;
  border-radius: 3px;
  font-size: 15px;
}
