.patientListItem {
  padding: 24px 32px;
  margin-bottom: 8px;
  gap: 16px;
  line-height: 1.5;
  align-items: center;
  width: 100%;
  border: 1px solid var(--accent-faint);
}

.mainContent {
  display: flex;
}

.start {
  display: flex;
  gap: 24px;
  flex: 1;
}

.patientIcon {
  width: 36px;
  margin-right: 8px;
}

.name {
  font-weight: 600;
  margin-bottom: 4px;
  font-size: 18px;
  line-height: 1;
}

.label {
  color: #616161;
  font-size: 14px;
}

.contactBox {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  max-width: 100%;
  font-size: 14px;
  color: #616161;
  margin-top: 4px;
  align-items: center;
  line-height: 1.1;
}

.contactDivider {
  font-size: 8px;
  vertical-align: middle;
}

.extraData {
  display: flex;
  gap: 48px;
}

.trashIcon {
  width: 24px;
  height: 24px;
}

.actions {
  margin-left: auto;
  display: flex;
  align-items: center;
  cursor: pointer;
  gap: 16px;
}

.actions button {
  background: 0;
  border: 0;
  cursor: pointer;
  padding: 0;
  margin: 0;
}

.actions img {
  margin: 0;
}

.actions button:active {
  opacity: 0.8;
}

.link {
  color: var(--color-primary);
  margin-bottom: 4px;
  display: block;
}

.actionButton {
  background: 0;
  border: 0;
  width: 32px;
  height: 32px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.error {
  color: var(--color-destructive);
}

.loader {
  text-align: center;
  padding: 24px 0;
}

.statusBox.active {
  color: #198754;
  border: 1px solid #198754;
}
.statusBox.active .statusIndicator {
  background-color: #198754;
}

.statusBox.inactive .statusIndicator {
  background-color: #dc3545;
}

.statusBox.inactive {
  color: #dc3545;
  border: 1px solid #dc3545;
}

.statusBox.pending .statusIndicator {
  background-color: #f57102;
}

.statusBox.pending {
  color: #f57102;
  border: 1px solid #f57102;
}

.statusBox {
  font-size: 11px;
  letter-spacing: 0.5px;
  font-weight: 500;
  text-transform: uppercase;
  padding: 6px 8px;
  border-radius: 3px;
  display: inline-flex;
  align-items: center;
  gap: 6px;
  align-self: flex-start;
  background: 0;
  border: 0;
}

.statusBox:not(:disabled):hover {
  background-color: var(--color-background);
  cursor: pointer;
}

.statusBox:disabled {
  cursor: text;
}

.statusIndicator {
  width: 7px;
  height: 7px;
  border-radius: 50%;
}

.setStatusBtn {
  align-self: flex-start;
  padding: 6px 16px;
  background: 0;
  border: 1px solid var(--color-primary);
  color: var(--color-primary);
  font-weight: 500;
  border-radius: 4px;
  cursor: pointer;
}

.setStatusBtn:hover {
  background-color: var(--color-background);
}
