.box {
  border: 1px solid var(--accent-faint);
  box-shadow: var(--default-box-shadow);
  border-radius: 10px;
  padding: 32px;
  width: 100%;
  box-sizing: border-box;
  background-color: white;
}

.errorMessage {
  text-align: center;
  color: var(--color-destructive);
  padding: 24px 0;
}

.loader {
  text-align: center;
  padding: 24px 0;
}

.detailGrid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 32px;
}

.detailItem {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.detailItemTitle {
  font-weight: 600;
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 1px;
  color: #757575;
}

.detailGridTitle {
  font-weight: 500;
  font-size: 18px;
}

.detailHeader {
  display: flex;
  gap: 8px;
  border-bottom: 1px solid var(--accent-faint);
  margin-bottom: 24px;
  padding-bottom: 12px;
}

.rxIcon {
  width: 24px;
}

.detailItemTextLink {
  color: var(--color-primary);
  font-weight: 500;
}

.iconButton {
  border: 0;
  background: 0;
  padding: 0;
  cursor: pointer;
  color: var(--color-primary);
  text-decoration: underline;
  width: 18px;
  height: 18px;
}

.prescriptionReceivedBox {
  display: flex;
  gap: 4px;
  align-items: center;
}
