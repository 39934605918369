.inventoryGrid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 16px;
}

.inventoryGridItem {
  flex: 1;
  background-color: white;
  box-shadow: var(--default-box-shadow);
  border: 1px solid var(--accent-faint);
  border-radius: 10px;
  overflow: hidden;
  padding: 24px;
}

.name {
  font-weight: 600;
  font-size: 20px;
  margin: 0;
  line-height: 1.2;
  padding-bottom: 8px;
}

.strength,
.stock {
  font-weight: 400;
  color: #757575;
  line-height: 1.5;
  font-size: 16px;
}

.price {
  font-weight: 700;
  padding-top: 16px;
  font-size: 24px;
  padding-bottom: 16px;
}

.data {
  padding-bottom: 16px;
}
