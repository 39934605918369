.default {
  background: 0;
  color: var(--color-primary);
  font-weight: bold;
  text-transform: uppercase;
  padding: 8px 24px;
  cursor: pointer;
  border-radius: 5px;
  border: 1px solid var(--color-primary);
  justify-content: center;
  align-items: center;
  display: flex;
  gap: 8px;
  background: white;
  transition: background-color 150ms ease-in-out;
  letter-spacing: 1px;
}

.primary {
  background-color: var(--color-primary);
  color: white;
}

.default:active {
  background-color: #f8f9fc;
}

.primary:active {
  background-color: #00b8e7;
}

.addToCartIcon {
  width: 20px;
  height: 20px;
}
