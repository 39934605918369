.help {
  display: inline-flex;
  width: 18px;
  height: 18px;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  position: relative;
  overflow: visible;
  text-align: center;
  cursor: default;
}

.tooltip {
  position: absolute;
  background-color: #424242;
  border: 1px solid #212121;
  padding: 8px 16px;
  border-radius: 4px;
  color: white;
  z-index: 1;
  visibility: hidden;
  top: 20px;
  width: 500px;
  line-height: 1.4;
  font-size: 14px;
}

.help:hover .tooltip:not(.tooltip:hover) {
  visibility: visible;
}
