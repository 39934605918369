@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&family=Poppins:wght@500;600;700&display=swap");
@import "./vars.css";

html {
  height: 100vh;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--color-black);
  font-family: "Inter";
  height: 100%;
}

button {
  font-family: "Inter";
}

h1,
h2,
h3,
p {
  margin: 0;
}

h1 {
  font-family: "Poppins";
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#root {
  height: 100%;
}

.table-container {
  width: 100%;
  overflow-x: auto; /* Enables horizontal scrolling */
  border: 1px solid var(--accent-faint);
  box-shadow: var(--default-box-shadow);
  border-radius: 10px; /* Maintain rounded corners */
}

.table {
  border-radius: 10px;
  border-spacing: 0;
  background-color: white;
  overflow: hidden;
  width: 100%;
  border-collapse: collapse; /* Ensures cells share borders */
  width: 100%;
  min-width: max-content; /* Ensures the table expands when needed */
  table-layout: auto; /* Allows columns to size dynamically */
  border-collapse: collapse;
}

.table th {
  text-align: start;
  letter-spacing: 1px;
  font-size: 11px;
  text-transform: uppercase;
  color: #757575;
  border-bottom: 1px solid var(--color-background);
  padding: 12px 12px;
  background-color: #fafafa;
  white-space: nowrap;
}

.table td:not(.table-empty-state) {
  line-height: 1.5;
  text-overflow: ellipsis;
  overflow: hidden;
  font-size: 14px;
}

.table td:not(.noPadding):not(.table-empty-state):not(td:first-child) {
  padding: 12px 14px;
}

.table tr:not(:last-child) td {
  border-bottom: 1px solid #eeeeee;
}

.table td:last-child,
.table th:last-child {
  border-right: none;
}

.table tr:last-child td:first-child:not(.table-empty-state) {
  border-bottom-left-radius: 7px;
}

.table tr:last-child td:last-child:not(.table-empty-state) {
  border-bottom-right-radius: 7px;
}

.table-link {
  color: var(--color-primary);
  font-weight: 500;
}

.table td:first-child,
.table th:first-child {
  padding-left: 24px;
}

.table th,
.table td {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.table-empty-state {
  padding: 16px 12px;
  text-align: center;
  width: 100%;
  color: #757575;
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 0.75px;
  font-weight: 500;
}

.subtitle {
  margin-bottom: 24px;
  font-size: 16px;
  line-height: 1.5;
  color: #616161;
}

.error {
  color: var(--color-destructive);
}

.error-small {
  font-size: 14px;
}
