.app {
  display: flex;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  scrollbar-gutter: stable;
  background-color: var(--color-background);
}

.content {
  flex: 5;
  overflow-x: hidden;
}

.mobile {
  display: "flex";
  justify-content: "center";
  align-items: "center";
  height: "100vh";
}
