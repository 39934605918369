.faqItem {
  box-shadow: var(--default-box-shadow);
  background-color: white;
  padding: 32px;
  border: 1px solid var(--accent-faint);
  margin-bottom: 16px;
  border-radius: 15px;
  text-align: start;
  cursor: pointer;
  transition: opacity 150ms ease-in-out;
}

.subtitle {
  color: #616161;
  line-height: 1.5;
  margin-bottom: 24px;
}

.faqItem:active {
  opacity: 0.8;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header h2 {
  letter-spacing: 0.5px;
  line-height: 1.3;
  font-weight: 600;
}

.chevron {
  width: 32px;
  height: 32px;
  transition: transform 400ms ease-in-out;
}

.answer {
  font-size: 16px;
  margin-top: 16px;
  color: #616161;
  line-height: 1.75;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.flip {
  transform: rotate(-180deg);
}

.tierCategory {
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 24px;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
}

.tier {
  margin-left: 8px;
  font-size: 16px;
  line-height: 1.75;
}
.tierList {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.tierHeading {
  font-weight: bold;
  letter-spacing: 1px;
  color: var(--color-black);
  font-size: 14px;
  margin-bottom: 8px;
}

.tierName {
  color: var(--color-black);
  font-weight: 600;
  text-decoration: underline;
}

.tierFactorList {
  margin: 0;
}

.tierFactorList li:not(:last-child) {
  margin-bottom: 8px;
}
