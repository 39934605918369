.sectionCard {
  max-width: 700px;
  padding: 24px;
  border: 1px solid var(--accent-faint);
}

.sectionCard:not(:last-child) {
  margin-bottom: 24px;
}

.personalInfoWrap {
}

.sectionTitle {
  font-size: 18px;
  font-weight: 600;
  padding-bottom: 12px;
  border-bottom: 1px solid var(--accent-faint);
  margin-bottom: 16px;
}

.nameInputs {
  display: flex;
  gap: 12px;
}

.inputContainer {
  width: 100%;
}

.inputContainer label {
  font-size: 14px;
  margin-bottom: 4px;
}

.label,
.input,
.select {
  display: block;
}

.input,
.select {
  width: 100%;
  box-sizing: border-box;
  padding: 8px;
  border: 1px solid #d6d6d6;
  border-radius: 4px;
  font-size: 15px;
}
.select {
  max-width: 400px;
}

.input {
  width: 100%;
}

.inputs {
  padding: 16px 24px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding-top: 16px;
  gap: 16px 24px;
  padding-bottom: 40px;
}

.submitWrap {
  display: flex;
  margin-top: 24px;
}

.toggleWrap {
  display: flex;
  gap: 24px;
  justify-content: space-between;
}

.toggleWrap:not(:first-child) {
  padding-top: 16px;
}

.toggleWrap:not(:last-child) {
  padding-bottom: 16px;
}

.toggleName {
  font-weight: 400;
  margin-bottom: 6px;
}

.toggleDescription {
  font-size: 15px;
  color: grey;
  line-height: 1.4;
}

.toggleWrap:not(:last-child) {
  border-bottom: 1px solid var(--color-background);
}
