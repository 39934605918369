.patientForm {
  width: 900px;
  padding: 32px;
  box-sizing: border-box;
  position: relative;
}

.card {
  position: relative;
}

.financialForm {
  width: 500px;
  padding: 32px;
}

.financialSubtitle {
  margin-top: 8px;
  line-height: 1.5;
  font-size: 14px;
  color: #616161;
}

.financialInputs {
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin: 24px 0;
  margin-bottom: 32px;
}

.inputGrid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 8px;
  margin: 24px 0;
}

.inputGrid {
  width: 50%;
  align-self: flex-start;
}

.inputGrid input {
  align-self: flex-start;
}

.fullSpanInput {
  grid-column: 1 / 3;
}

.exit {
  position: absolute;
  right: 12px;
  top: 16px;
  background: 0;
  border: 0;
  cursor: pointer;
  z-index: 1;
}

.exit img {
  width: 12px;
  opacity: 50%;
}

.networkError {
  color: var(--color-destructive);
  font-size: 14px;
  margin-top: 8px;
}

/* input[type="date"]::-webkit-inner-spin-button,
input[type="date"]::-webkit-calendar-picker-indicator {
  display: none;
  -webkit-appearance: none;
} */

.formSection {
  display: flex;
  gap: 24px;
}

.buttonWrap {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  gap: 24px;
}

.financialButtonWrap {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.communication {
  display: flex;
  gap: 8px;
}

.communicationLabel {
  color: #616161;
}

.communicationLabel,
.communication {
  margin: 8px 0;
  font-size: 14px;
}

.communication label {
  font-size: 14px;
}

.communication input {
  margin-right: 4px;
}

.signatureCheckbox {
  margin-right: 8px;
}

.signatureLabel {
  font-size: 14px;
}

.cancelButton {
  border: 0;
  padding: 0;
  background: 0;
  display: block;
  cursor: pointer;
  font-size: 15px;
  font-weight: 600;
  color: #616161;
  text-decoration: none;
}

.select {
  border: 1px solid #cfcfcf;
  border-radius: 4px;
  height: 40px;
  font-size: 16px;
  color: var(--color-black);
  padding: 6px;
}

.error {
  font-size: 14px;
  color: var(--color-destructive);
  margin-top: 8px;
}
