.title {
  font-size: 16px;
  padding-bottom: 8px;
  font-weight: 500;
}

.condensed .title {
  font-size: 14px;
  padding-bottom: 4px;
}

.feeBox {
  display: flex;
  flex-direction: column;
  padding-bottom: 8px;
  margin-bottom: 8px;
  border-bottom: 1px solid #e0e0e0;
}

.condensed .feeBox {
  padding-bottom: 4px;
  margin-bottom: 4px;
}

.fee {
  line-height: 2;
  display: flex;
  justify-content: space-between;
  font-size: 14px;
}

.condensed .fee {
  line-height: 1.3;
}

.feeLabel {
  display: flex;
  gap: 4px;
  align-items: center;
}

.total {
  font-size: 16px;
  font-weight: 600;
  margin-top: 8px;
}

.condensed .total {
  font-size: 14px;
}

.total span {
  font-weight: 400;
  font-size: 14px;
}

.condensed .total span {
  font-size: 12px;
}

.popup {
  padding: 32px;
  width: 500px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.popupTitle {
  font-size: 24px;
}

.buttonWrap {
  margin-top: 16px;
}

.subtitle {
  color: #616161;
}
