.option {
  width: 150px;
  padding: 10px;
  background-color: white;
  text-align: left;
  cursor: pointer;
  border: 0;
}

.option:hover {
  background-color: #f5f5f5;
}

.moreOptions {
  position: absolute;
  border-radius: 8px;
  z-index: 1;
  overflow: hidden;
  /* bottom: 8px; */
  right: 0;
  box-shadow: var(--default-box-shadow);
  border: 1px solid #bdbdbd;
  margin-top: 4px;
}

.moreOptionsButtonBase {
  position: relative;
}

.moreOptionsButton {
  width: 24px;
  height: 24px;
  background: 0;
  border: 0;
  padding: 0;
  cursor: pointer;
  position: relative;
}

.moreOptionsButton:active {
  opacity: 0.8;
}

.moreOptionsButton img {
  width: 24px;
  height: 24px;
}
