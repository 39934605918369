.pageDescription {
  margin-bottom: 24px;
}

.error {
  color: var(--color-destructive);
}

.inputError {
  color: var(--color-destructive);
  font-size: 14px;
  margin-top: 6px;
}

.form {
  max-width: 40rem;
}

.pageBody {
  background-color: white;
  border: 1px solid var(--accent-faint);
  border-radius: 15px;
  box-shadow: var(--default-box-shadow);
  overflow: hidden;
  margin-bottom: 32px;
}

.pageBodyHeading {
  padding: 16px 24px;
  width: 100%;
  border-bottom: 1px solid var(--color-background);
  background-color: #fafafa;
  font-size: 12px;
  color: #757575;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 600;
}

.inputContainer {
  margin-bottom: 16px;
}

.inputContainer label {
  font-size: 14px;
  margin-bottom: 4px;
}

.label,
.input,
.select {
  display: block;
}

.input,
.select {
  width: 100%;
  box-sizing: border-box;
  padding: 8px;
  border: 1px solid #d6d6d6;
  border-radius: 3px;
  font-size: 15px;
  max-width: 400px;
}

.inputs {
  padding: 16px 24px;
  padding-top: 16px;
}

.submitWrap {
  display: flex;
  justify-content: flex-end;
  gap: 24px;
  align-items: center;
}

.cancelButton {
  border: 0;
  padding: 0;
  background: 0;
  display: block;
  cursor: pointer;
  font-size: 15px;
  font-weight: 600;
  color: #616161;
  text-decoration: none;
}
