.task {
  width: 66%;
  background-color: white;
  padding: 24px;
  border-radius: 7px;
  box-shadow: var(--default-box-shadow);
  margin-bottom: 16px;
  align-items: center;
  display: flex;
}
.taskBody {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.task h2 {
  font-size: 18px;
  line-height: 1.5;
  font-weight: 600;
  margin-bottom: 2px;
  color: var(--color-primary);
}

.task p {
  line-height: 1.5;
  font-size: 15px;
  color: #757575;
}

.prescription {
  font-weight: 500;
}

.actionButton {
  background: 0;
  border: 0;
  width: 32px;
  height: 32px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.actionButton:active {
  opacity: 0.8;
}

.actionButton img {
  width: 27px;
  height: 27px;
}
