.searchWrap {
  margin-bottom: 16px;
  display: flex;
  gap: 16px;
  justify-content: space-between;
  align-items: center;
  height: 40px;
}

.label {
  font-size: 12px;
  margin-bottom: 4px;
  display: block;
}

.dateWrap {
  box-shadow: var(--default-box-shadow);
  border: 1px solid var(--accent-faint);
  background-color: white;
  border-radius: 6px;
  flex-shrink: 0;
  height: 100%;
  padding: 0 12px;
  display: flex;
  align-items: center;
  font-size: 14px;
  box-sizing: border-box;
  line-height: 1;
  color: var(--color-primary);
  gap: 8px;
  font-weight: 500;
  min-width: 250px;
}

.dateWrap:hover {
  background-color: var(--color-background);
  cursor: pointer;
}

.calendarIcon {
  width: 18px;
  height: 18px;
}

.dateSelectionCard {
  padding: 32px;
  width: 500px;
}

.dateSelectionDescription {
  margin-bottom: 16px;
  line-height: 1.4;
}

.dateSelectionCard h3 {
  line-height: 1.3;
  margin-bottom: 16px;
  font-size: 20px;
  padding-bottom: 8px;
  border-bottom: 1px solid var(--accent-faint);
}

.dateSelectionCard label {
  font-size: 14px;
  margin-bottom: 4px;
  display: block;
}

.inputs {
  display: flex;
  gap: 8px;
  margin-bottom: 24px;
}

.input {
  width: 100%;
  box-sizing: border-box;
  padding: 8px;
  border: 1px solid #d6d6d6;
  border-radius: 4px;
  font-size: 15px;
  cursor: text;
}

.inputWrap {
  gap: 8px;
  width: 100%;
  flex: 1;
}

.dateSelectionActions {
  display: flex;
  gap: 10px;
  justify-content: flex-end;
}

.quickOptions {
  margin-bottom: 16px;
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

.quickOptions button {
  border: 1px solid var(--color-primary);
  background: 0;
  text-transform: uppercase;
  color: var(--color-primary);
  font-size: 11px;
  font-weight: 500;
  letter-spacing: 0.75px;
  padding: 4px 8px;
  border-radius: 2px;
  cursor: pointer;
}

.quickOptions button:hover {
  background-color: var(--color-background);
}

.quickOptionsTitle {
  font-size: 13px;
  padding-bottom: 4px;
  margin-bottom: 12px;
  text-transform: uppercase;
  border-bottom: 1px solid var(--accent-faint);
}
