.consentFormButton {
  border: 1px solid var(--color-primary);
  background: white;
  border-radius: 5px;
  color: var(--color-primary);
  padding: 8px 16px;
  font-weight: 500;
  font-size: 15px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px;
}

.consentFormButton:hover {
  background-color: var(--color-background);
}

.linkIcon {
  width: 20px;
}

.table {
  box-shadow: var(--default-box-shadow);
  border-radius: 10px;
  border-spacing: 0;
  background-color: white;
  overflow: hidden;
  width: 100%;
  border: 1px solid var(--accent-faint);
  border-collapse: collapse; /* Ensures cells share borders */
}

.table th {
  text-align: start;
  letter-spacing: 1px;
  font-size: 12px;
  text-transform: uppercase;
  color: #757575;
  border-bottom: 1px solid var(--color-background);
  padding: 12px 12px;
  background-color: #fafafa;
  min-width: 100px;
  white-space: nowrap;
}

.table td:not(.emptyState) {
  line-height: 1.5;
  text-overflow: ellipsis;
  overflow: hidden;
  font-size: 14px;
}

.table td:not(.noPadding):not(.emptyState):not(td:first-child) {
  padding: 10px 12px;
}

.table tr:not(:last-child) td {
  border-bottom: 1px solid #eeeeee;
}

.table td:last-child,
.table th:last-child {
  border-right: none;
}

.table tr:last-child td:first-child:not(.emptyState) {
  border-bottom-left-radius: 7px;
}

.table tr:last-child td:last-child:not(.emptyState) {
  border-bottom-right-radius: 7px;
}

.patientLink {
  color: var(--color-primary);
  font-weight: 500;
}

.table td:first-child,
.table th:first-child {
  padding-left: 32px;
}

.prescriptionsSection {
  margin-top: 24px;
}

.prescriptionLink {
  color: var(--color-primary);
  font-weight: 500;
}

.emptyState {
  padding: 32px;
  text-align: center;
  width: 100%;
  color: #757575;
  font-size: 13px;
  text-transform: uppercase;
  letter-spacing: 0.75px;
  font-weight: 600;
}

.actions {
  display: flex;
  align-items: center;
  gap: 12px;
}

.plusIcon {
  width: 16px;
  height: 16px;
}

.editPatientStatusCard {
  padding: 32px;
  width: 500px;
}

.editPatientStatusDescription {
  margin-bottom: 16px;
  line-height: 1.4;
}

.editPatientStatusCard h3 {
  line-height: 1.3;
  margin-bottom: 12px;
  font-size: 20px;
}

.editPatientStatusCard label {
  font-size: 14px;
  margin-bottom: 4px;
  display: block;
}

.editPatientStatusActions {
  display: flex;
  gap: 10px;
  justify-content: flex-end;
}

.inputContainer {
  margin-bottom: 12px;
}

.inputcontainer:last-child {
  margin-bottom: 24px;
}

.input {
  width: 100%;
  box-sizing: border-box;
  padding: 8px;
  border: 1px solid #d6d6d6;
  border-radius: 4px;
  font-size: 15px;
}

textarea.input {
  resize: none;
  font-size: 14px;
}
