.tableLink {
  color: var(--color-primary);
  text-decoration: underline;
}

.iconButton {
  border: 0;
  background: 0;
  padding: 0;
  color: var(--color-primary);
  text-decoration: underline;
  line-height: 0;
}

.iconButton:not(:disabled) {
  cursor: pointer;
}

.iconButton img {
  width: 24px;
  height: 24px;
}

.dispenseActions {
  display: flex;
  align-items: center;
  gap: 12px;
  justify-content: center;
}

.editDispenseDateCard {
  padding: 32px;
  width: 500px;
}

.editDispenseDateDescription {
  margin-bottom: 16px;
  line-height: 1.4;
}

.editDispenseDateCard h3 {
  line-height: 1.3;
  margin-bottom: 12px;
  font-size: 20px;
}

.editDispenseDateCard label {
  font-size: 14px;
  margin-bottom: 4px;
  display: block;
}

.editDispenseDateActions {
  display: flex;
  gap: 10px;
  justify-content: flex-end;
}

.inputContainer {
  margin-bottom: 24px;
}

.error {
  font-size: 13px;
  color: var(--color-destructive);
  margin-top: 4px;
}

.input {
  width: 100%;
  box-sizing: border-box;
  padding: 8px;
  border: 1px solid #d6d6d6;
  border-radius: 4px;
  font-size: 15px;
  cursor: text;
}

.loader {
  border: 4px solid #f3f3f3; /* Light grey */
  border-top: 4px solid var(--color-primary); /* Blue */
  border-radius: 50%;
  width: 16px;
  height: 16px;
  animation: spin 1s linear infinite;
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
