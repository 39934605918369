.dashboardPageBody {
  background-color: var(--color-background);
  min-height: 100%;
  padding: 32px 8%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow-x: hidden;
}

.headerBox {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.headerWrap {
  padding-bottom: 24px;
}

.title {
  margin: 0;
  font-size: 32px;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
}

.headerPrefix {
  display: flex;
  gap: 24px;
  align-items: center;
}

.backLink {
  width: 20px;
  cursor: pointer;
  margin-top: 3px;
}

.description {
  margin-top: 8px;
  font-size: 15px;
  font-weight: 500;
  opacity: 0.8;
  line-height: 1.5;
}
