.medicationPopup {
  position: fixed;
  inset: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.card {
  background: white;
  border-radius: 10px;
  padding: 32px;
  display: flex;
  margin: 0 auto;
  gap: 8px;
  box-sizing: border-box;
  width: 500px;
  position: relative;
  flex-direction: column;
  max-height: 800px;
}

.select {
  width: 100%;
  box-sizing: border-box;
  padding: 8px;
  border: 1px solid #d6d6d6;
  border-radius: 3px;
  font-size: 15px;
}

.medicationFees {
  padding: 12px 16px;
  border: 1px solid #e0e0e0;
  background-color: #fafafa;
  border-radius: 5px;
}

.content {
  display: flex;
  line-height: 1.5;
}

.name {
  font-size: 24px;
  font-weight: bold;
  line-height: 1.3;
}

.strength,
.stock,
.treatmentLength {
  color: #757575;
}

.price {
  font-size: 24px;
  font-weight: 600;
  margin-top: 16px;
}

.genericName {
  font-weight: 400;
  font-size: 14px;
  color: #757575;
}

.inputContainer label {
  font-size: 14px;
  margin-bottom: 4px;
  display: block;
}

.inputs {
  margin-top: 12px;
}

.actions {
  display: flex;
  justify-content: flex-end;
  gap: 16px;
  margin-top: 24px;
}

.cancelButton {
  border: 0;
  padding: 0;
  background: 0;
  display: block;
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
  color: #616161;
  text-decoration: none;
}

.error {
  color: var(--color-destructive);
  font-size: 13px;
  margin-top: 4px;
}
