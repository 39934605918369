.inventoryList {
  background-color: white;
  border-radius: 15px;
  border: 1px solid var(--accent-faint);
  overflow: hidden;
  box-shadow: var(--default-box-shadow);
}

.inventoryListItem {
  padding: 12px 32px;
  display: flex;
  align-items: center;
}

.inventoryListItem:not(:last-child) {
  border-bottom: 1px solid var(--accent-faint);
}

.shaded {
  background-color: #fbfcfd;
}

.nameWrap {
  width: 66%;
  gap: 16px;
  flex: 3;
}

.name {
  font-weight: 500;
  font-size: 17px;
  line-height: 1.2;
  cursor: pointer;
}

.name:active {
  opacity: 0.8;
}

.genericName,
.price {
  font-weight: 500;
}

.stock {
  line-height: 1.5;
  font-size: 15px;
  color: #757575;
  flex: 2;
}

.buttonWrap {
  display: flex;
  justify-content: center;
}

.price {
  font-weight: 600;
  font-size: 16px;
  margin-right: 10%;
}

.index {
  width: 5%;
  text-align: center;
}

.link {
  margin-left: 4px;
  color: var(--color-primary);
  font-weight: 500;
  line-height: 1.5;
}

.selectButton {
  padding: 8px 24px;
  border: 1px solid var(--color-primary);
  background: 0;
  color: var(--color-primary);
  text-transform: uppercase;
  font-weight: bold;
  font-family: "Inter";
  border-radius: 5px;
  cursor: pointer;
}
.selectButton:active {
  background-color: #fafafa;
}
