.tableContainer {
  width: 100%;
  overflow-x: auto; /* Enables horizontal scrolling */
  border: 1px solid var(--accent-faint);
  box-shadow: var(--default-box-shadow);
  border-radius: 10px; /* Maintain rounded corners */
}

.table {
  border-radius: 10px;
  border-spacing: 0;
  background-color: white;
  overflow: hidden;
  width: 100%;
  border-collapse: collapse; /* Ensures cells share borders */
  width: 100%;
  min-width: max-content; /* Ensures the table expands when needed */
  table-layout: auto; /* Allows columns to size dynamically */
  border-collapse: collapse;
}

.table th {
  text-align: start;
  letter-spacing: 1px;
  font-size: 11px;
  text-transform: uppercase;
  color: #757575;
  border-bottom: 1px solid var(--color-background);
  padding: 12px 12px;
  background-color: #fafafa;
  white-space: nowrap;
}

.table td:not(.emptyState) {
  line-height: 1.5;
  text-overflow: ellipsis;
  overflow: hidden;
  font-size: 14px;
}

.table td:not(.noPadding):not(.emptyState):not(td:first-child) {
  padding: 12px 14px;
}

.table tr:not(:last-child) td {
  border-bottom: 1px solid #eeeeee;
}

.table td:last-child,
.table th:last-child {
  border-right: none;
}

.table tr:last-child td:first-child:not(.emptyState) {
  border-bottom-left-radius: 7px;
}

.table tr:last-child td:last-child:not(.emptyState) {
  border-bottom-right-radius: 7px;
}

.tableLink {
  color: var(--color-primary);
  font-weight: 500;
}

.table td:first-child,
.table th:first-child {
  padding-left: 24px;
}

.table th,
.table td {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  /* max-width: 64px; */
}

.emptyState {
  padding: 32px;
  text-align: center;
  width: 100%;
  color: #757575;
  font-size: 13px;
  text-transform: uppercase;
  letter-spacing: 0.75px;
  font-weight: 600;
}

.filters {
  display: flex;
  gap: 8px;
  margin-bottom: 16px;
}

.filter {
  background-color: white;
  border-radius: 500px;
  padding: 6px 16px;
  font-size: 14px;
  border: 1px solid var(--color-primary);
  color: var(--color-primary);
  font-weight: 500;
  cursor: pointer;
  transition: background-color 150ms ease;
}

.filterInactive:hover {
  background-color: var(--color-background);
}

.filterActive:hover {
  background-color: #0093b8;
}

.filterActive {
  background-color: var(--color-primary);
  color: white;
}

.statusIcon {
  width: 22px;
  height: 22px;
  vertical-align: middle;
  text-align: center;
}

.statusText {
  font-size: 11px;
  font-weight: 400;
  letter-spacing: 0.5px;
}

.statusText img {
  margin-right: 4px;
}

.statusTextGreen {
  color: green;
}

.statusTextRed {
  color: maroon;
}

.subtitle {
  margin-bottom: 24px;
  font-size: 16px;
  line-height: 1.5;
  color: #616161;
}
