.suffix {
  display: flex;
  justify-content: space-between;
  gap: 16px;
  padding-bottom: 16px;
  align-items: center;
}

.body {
  height: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
}

.content {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.controls {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 48px;
  padding-bottom: 24px;
}

.noResults {
  text-align: center;
  font-weight: 500;
  align-self: center;
  justify-self: center;
}
