.stagedDispenseActions {
  display: flex;
  align-items: center;
  gap: 8px;
}

.stagedDispense {
  border-bottom: 1px solid var(--color-background);
}

.stagedDispense:not(:first-child) {
  padding: 12px 0;
}

.stagedDispense:first-child {
  padding-bottom: 12px;
}

.stagedDispenseContent {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.iconButton {
  border: 0;
  background: 0;
  padding: 0;
  cursor: pointer;
  color: var(--color-primary);
  text-decoration: underline;
  width: 22px;
  height: 22px;
}

.iconButton img {
  vertical-align: middle;
  width: 22px;
  height: 22px;
}

.loader {
  border: 3px solid #f3f3f3; /* Light grey */
  border-top: 3px solid var(--color-primary); /* Blue */
  border-radius: 50%;
  width: 12px;
  height: 12px;
  animation: spin 1s linear infinite;
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
